/* global PRODUCTION VUE */
// import Vue from 'vue';
import { metrics } from './theme/scripts/ferg-metrics.js';

console.log(`${PRODUCTION ? 'Production Environment' : 'Dev Environment'}`);

if (PRODUCTION) {
	// Production-specific settings go here.
	// console.log = () => {};
} else {
	metrics();
}

document.addEventListener('DOMContentLoaded', function () {
	if (!PRODUCTION) console.log('DOMContentLoaded');

	// initVue();
	handleMobileAppBrandsNavAndDropDownToggle();
	handleHamburgers();
	handleDropdowns();

	const accordions = document.querySelectorAll(".accordion");
	accordions.forEach(function (accordion) {
		const header = accordion.querySelector(".accordion-header");
		header.addEventListener("click", function () {
			const body = accordion.querySelector(".accordion-body");
			const toggleIcon = header.querySelector("span");
			const isActive = body.classList.toggle("active");

			// Remove 'header-active' class from all accordion headers
			const allHeaderParagraphs = document.querySelectorAll(".accordion-header p");
			allHeaderParagraphs.forEach(function (paragraph) {
				paragraph.classList.remove("header-active");
			});

			// Close other accordion items when clicking on one.
			if (isActive) {
				const allAccordions = document.querySelectorAll(".accordion");
				allAccordions.forEach(function (item) {
					if (item !== accordion) {
						const otherBody = item.querySelector(".accordion-body");
						const otherToggleIcon = item.querySelector("span");
						otherBody.classList.remove("active");
						otherBody.style.maxHeight = "0";
						otherToggleIcon.textContent = "+";
					}
				});
			}

			// Add the class "header-active" to the clicked accordion header paragraph
			const headerParagraph = header.querySelector("p");
			if (isActive) {
				headerParagraph.classList.add("header-active");
			} else {
				headerParagraph.classList.remove("header-active");
			}

			// animation
			const height = isActive ? body.scrollHeight + "px" : "0";
			body.style.maxHeight = height;
			toggleIcon.textContent = isActive ? "-" : "+";
		});
	});

	var tabLinks = document.querySelectorAll('.custom-tabs ul li a');
	tabLinks.forEach(function (link) {
		link.addEventListener('click', function (event) {
			event.preventDefault();
			var targetPanelId = this.getAttribute('href').substring(1);
			var targetPanel = document.getElementById(targetPanelId);
			var allPanels = document.querySelectorAll('.custom-tab-panel');
			allPanels.forEach(function (panel) {
				panel.classList.remove('is-active');
			});
			targetPanel.classList.add('is-active');
			var allTabLinks = document.querySelectorAll('.custom-tabs ul li');
			allTabLinks.forEach(function (tab) {
				tab.classList.remove('is-active');
			});
			this.parentNode.classList.add('is-active');
		});
	});



	const goToWithArrowIcon = document.querySelector('#arrowIcon');
	const searchIcon = document.querySelector('.search-icon');
	const searchContainer = document.querySelector('.search-nav-input-container');
	const appNavbar = document.querySelector('#app-navbar');
	const searchInput = searchContainer.querySelector('input');

	const searchContainerMobile = document.querySelector('.search-container-mobile');
	const searchIconMobile = document.querySelector('.mobile-search');
	const appNavbarMobile = document.querySelector('#nav-bar-mobile');
	const searchInputMobile = searchContainerMobile.querySelector('input');
	const goToWithArrowIconDesktop = document.querySelector('#arrow-icon-desktop');
	const searchNavInputMobile = document.querySelector('.search-nav-input.mobile');

	function performSearch(inputValue) {
		if (inputValue.length > 0) {
			window.location.href = `/?s=${encodeURIComponent(inputValue)}`;
		}
	}

	function handleSearch(container, navbar, input) {
		container.classList.toggle('expanded');
		input.focus();
	}


	searchIcon.addEventListener('click', function () {
		handleSearch(searchContainer, appNavbar, searchInput);
	});

	searchIconMobile.addEventListener('click', function () {
		handleSearch(searchContainerMobile, appNavbarMobile, searchInputMobile);
	});


	searchInput.addEventListener('keypress', function (event) {
		if (event.key === 'Enter') {
			performSearch(searchInput.value);
		}
	});

	goToWithArrowIcon.addEventListener('click', function () {
		performSearch(searchInputMobile.value);
	});

	goToWithArrowIconDesktop.addEventListener('click', function () {
		performSearch(searchInput.value);
	});


	searchInputMobile.addEventListener('keypress', function (event) {
		if (event.key === 'Enter') {
			performSearch(searchInputMobile.value);
		}
	});
});


function handleHamburgers() {
	const navbarBurgers = document.querySelectorAll('.navbar-burger, .brand-menu-trigger'); // Check if there are any navbar burgers
	if (navbarBurgers.length > 0) {
		navbarBurgers.forEach(function (el) {
			el.addEventListener('click', function () {
				const target = el.dataset.target;
				const $target = document.getElementById(target); // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"

				el.classList.toggle('is-active');
				$target.classList.toggle('is-active');
			});
		});
	}
}

function handleMobileAppBrandsNavAndDropDownToggle() {
	const brandsDropdownTrigger = document.querySelector('.brand-menu-trigger');
	const nav = document.querySelector('.application-menu-mobile');
	const navbarBurger = document.querySelector('.navbar-burger');
	if (brandsDropdownTrigger && nav && navbarBurger) {
		brandsDropdownTrigger.addEventListener('click', function () {
			nav.classList.remove('is-active');
			navbarBurger.classList.remove('is-active');
		});
	}
}



function handleDropdowns() {
	const dropdowns = document.querySelectorAll('.navbar-dropdown'); // Check if there are any navbar burgers

	if (dropdowns.length > 0) {
		dropdowns.forEach(function (el) {
			const navbarItem = el.parentElement;
			const toggle = navbarItem.querySelector('.navbar-link');

			toggle.addEventListener('click', function () {
				navbarItem.classList.toggle('is-active');

				document.addEventListener('click', function _listener(e) {
					if (!navbarItem.contains(e.target)) {
						navbarItem.classList.toggle('is-active');
						document.removeEventListener('click', _listener);
					}
				});
			});
		});
	}
}

// async function initVue() {
// 	let appElements = document.querySelectorAll('[data-vue]');

//     if (appElements.length > 0) {
//         const {loadApp} = await import('./vue-app-loader');

//         appElements.forEach(loadApp);
//     }
// }
